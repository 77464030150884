


// You can define colors here (using http://mcg.mbitson.com)
$mat-primary: ( 50 : #e5eff5,
100 : #bfd6e6,
200 : #94bbd5,
300 : #69a0c4,
400 : #488bb7,
500 : #8b84d7,
600 : #246fa3,
700 : #1e6499,
800 : #185a90,
900 : #0f477f,
A100 : #b2d5ff,
A200 : #7fb9ff,
A400 : #4c9eff,
A700 : #3290ff,
contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
));

$mat-accent: (50 : #ffefea,
  100 : #fed7c9,
  200 : #febda6,
  300 : #fda282,
  400 : #fc8e67,
  500 : #f8485e,
  600 : #fc7245,
  700 : #fb673c,
  800 : #fb5d33,
  900 : #fa4a24,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffd5cd,
  A700 : #ffbfb3,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

// mandatory stuff for theming
$egret-primary: mat-palette($mat-primary);
$egret-accent: mat-palette($mat-accent);

// include the custom theme components into a theme object
$egret-theme: mat-light-theme($egret-primary, $egret-accent);

$primary-dark: darken(map-get($mat-primary, 500), 8%);

// include the custom theme object into the angular material theme
.ebo-theme {

  @include angular-material-theme($egret-theme);
  // @include multi-line-option-theme($egret-theme);
  font-family: $font-family-base !important;
  font-style: normal;
  //font-weight: $font-weight-medium;
  font-display: swap;

  [class^='mat-'],
  [class*=' mat-'] {
    font-family: $font-family-base !important;
    font-style: normal;
    //font-weight: $font-weight-medium;
    font-display: swap;
  }

  a {
    color: black !important;
  }

  input,
  textarea,
  select,
  button {
    font-family: $font-family-base !important;
    font-style: normal;
    //font-weight: $font-weight-medium;
    font-display: swap;
  }

  .material-icons {
    font-family: 'Material Icons' !important;
  }

  font-size: 15px;

  .card-content-wrapper {
    padding-top: 1.5rem !important;
    padding-bottom: 2rem !important;
  }

  .card-content-step-wrapper {
    padding-bottom: 2rem !important;
  }

  .card-content-actions-wrapper {
    padding-top: 1rem !important;
  }

  .mat-checkbox-inner-container {
    width: 14px;
    height: 14px;
  }

  .mat-card-subtitle,
  .mat-card-content,
  .mat-card-header .mat-card-title,
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    font-size: 14px;
  }

  input,
  textarea {
    font-size: 14px;
  }

  :focus {
    outline: none !important;
  }

  .mat-step-header .mat-step-icon,
  .mat-step-header .mat-step-icon-not-touched {
    color: #ffffff;
  }

  .mat-step-label-selected {
    font-size: 15px !important;
  }

  .mat-step-label {
    font-size: 15px !important;
  }

  .stepper-sub-title {
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    margin-bottom: 20px;
  }

  button.mat-primary,
  button.mat-accent {
    //font-weight: bold !important;
    letter-spacing: 0.04em !important;
    text-transform: uppercase !important;
    color: #ffffff !important;
  }

  button.mat-secondary {
    letter-spacing: 0.04em !important;
    text-transform: uppercase !important;
  }

  mat-icon {
    user-select: none;
  }

  .mat-drawer-container {
    background-color: #ffffff;
  }

  .mat-divider {
    border-color: #d8d8dc !important;
  }

  .mat-bg-primary,
  .topbar,
  .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
  .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
  .fileupload-drop-zone.dz-file-over,
  .toolbar-avatar.online > .status-dot,
  .cal-open-day-events,
  div.hopscotch-bubble {
    background: #ffffff !important;
    color: $main-title-color !important;
  }

  .mat-color-primary,
  .list-item-active {
    color: map-get($mat-primary, 500);
  }

  .sidebar-panel .mat-nav-list .mat-list-item {
    color: rgba(0, 0, 0, 0.94)
  }

  .topnav ul.menu > li > div.open > a,
  .topnav ul.menu > li > div.open > div,
  .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > .sub-menu,
  .list-item-active {
    border-color: map-get($mat-primary, 500);
  }

  .sidebar-full .sidenav-hold li.open,
  .sidebar-full .sidenav-hold li.open li.open {
    background: #ffffff;
  }

  .sidenav-hold .sidenav li.open > a > span,
  .sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge) {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
  }

  .sidenav-hold .sidenav li a .mat-icon:not(.menu-caret) {
    color: $secondary-standard-background;
  }

  .mat-tooltip {
    margin: 8px !important;
  }

  .sidenav-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 54px;
    background: $secondary-standard-background;
    width: 100%;
  }

  .test-bot-button {
    height: 48px;
    width: 48px;
    margin-top: 64px;
    margin-left: 8px;
  }

  .test-bot-button .mat-icon {
    color: $main-standard-background;
    height: 48px;
    width: 48px;
    line-height: 48px;
  }

  .card-button-icon {
    color: $main-standard-background;
    font-size: 40px;
    width: 40px;
    height: 40px;
  }

  .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
    color: $main-title-color;
    font-weight: bold;
  }

  .conversations-hold .user-message .conversation-msg {
    background-color: $main-title-color;
    border-radius: 0 4px 4px 4px;
  }

  .conversations-hold .bot-message .conversation-msg {
    background-color: #f1f1f1;
    border-radius: 4px 4px 0 4px;
  }

  .chat-input-actions .send-message-input input::-webkit-input-placeholder,
  .chat-input-actions .send-message-input input::-moz-placeholder,
  .chat-input-actions .send-message-input input:-ms-input-placeholder,
  .chat-input-actions .send-message-input input::placeholder {
    color: #666666 !important;
  }

  .sidebar-panel .toggle-collapsed {
    height: 54px;
    width: 66px;
    line-height: 44px;
    position: absolute;
  }

  .sidebar-panel .toggle-collapsed .mat-icon {
    height: 44px;
    width: 44px;
    line-height: 44px;
    font-size: 32px;
    color: #fff;
  }

  .sidebar-panel .app-version {
    position: absolute;
    bottom: 18px;
    right: 25px;
    font-size: 12px;
    color: #d8d8dc;
    display: inline-block;
  }

  @media only screen and (max-height: 500px) {
    .sidebar-panel .toggle-collapsed {
      position: initial;
      margin-left: 9px;
      float: left;
    }

    .sidebar-panel .app-version {
      position: initial;
      float: right;
      margin-right: 25px;
      margin-top: 18px;
    }
  }

  .sidebar-compact .app-version {
    display: none !important;
  }

  .sidebar-panel, .sidebar-compact .sidebar-panel,
  .sidebar-compact .navigation-hold {
    background: #ffffff;
  }

  .sidebar-compact ul.submenu,
  .breadcrumb-bar,
  .default-bg {
    background: #ffffff !important;
  }

  .sidebar-compact .branding {
    top: 10px !important;
  }

  .sidebar-compact .sidebar-full .sidenav-hold li.open,
  .sidebar-compact .sidebar-full .sidenav-hold li.open li.open {
    background-color: $secondary-bg-color !important;
  }

  /* Hopscotch Tour */
  div.hopscotch-bubble {
    border-color: $primary-dark;
  }

  /* up arrow  */
  div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
    border-bottom: 17px solid $primary-dark;
  }

  div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
    border-bottom: 17px solid map-get($mat-primary, 500);
    top: -16px;
  }

  /* right arrow */
  div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
    border-left: 17px solid $primary-dark;
  }

  div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
    border-left: 17px solid map-get($mat-primary, 500);
    left: -1px;
  }

  /* bottom arrow */
  div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
    border-top: 17px solid $primary-dark;
  }

  div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
    border-top: 17px solid map-get($mat-primary, 500);
    top: -18px;
  }

  /* Left Arrow */
  div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
    border-right: 17px solid $primary-dark;
  }

  div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
    left: 1px;
    border-right: 17px solid map-get($mat-primary, 500);
  }

  .mat-bg-accent,
  div.hopscotch-bubble .hopscotch-bubble-number,
  div.hopscotch-bubble .hopscotch-nav-button.next:hover,
  div.hopscotch-bubble .hopscotch-nav-button.next {
    background-color: map-get($mat-accent, 500);
    color: black;
  }

  .mat-bg-warn {
    background-color: #f2524f;
    color: white;
  }

  .mat-color-accent {
    color: map-get($mat-accent, 500);
  }

  .mat-color-warn {
    color: #f8485e;
  }

  .mat-color-default {
    color: rgba(0, 0, 0, 0.87);
  }

  .mat-raised-button.mat-warn,
  .mat-fab.mat-warn,
  .mat-mini-fab.mat-warn {
    background-color: #f8485e;
  }

  .ngx-datatable.material.single-selection .datatable-body-row.active,
  .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
  .ngx-datatable.material.multi-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: map-get($mat-primary, 500);
    color: #FFF;
  }

  .ngx-datatable.material.single-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
    background-color: map-get($mat-accent, 500);
    color: #FFF;
  }

  .ngx-datatable.material.single-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
    background-color: map-get($mat-accent, 200);
    color: #FFF;
  }

  .ngx-datatable.material .datatable-body .datatable-group-header {
    background: #ffffff;
  }

  .mat-card:not([class*=mat-elevation-z]),
  .mat-box-shadow,
  .chat-sidenav {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2) !important;
  }

  #sidebar-right {
    -webkit-box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.15);
  }

  .topbar {
    color: $main-title-color !important;
    font-family: $font-family-base !important;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.31px;
  }

  .topbar.mat-toolbar .mat-select,
  .topbar-mat-select {
    text-transform: uppercase !important;
  }

  .topbar.mat-toolbar .mat-select-value {
    font-weight: bold;
  }

  .bot-name {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
  }

  .mat-card {
    // margin: 25px 10px 0 0;
    border-radius: 4px;
  }

  .mat-card-content,
  .mat-card-content .mat-box-shadow,
  .mat-card-content .mat-box-shadow .ngx-datatable {
    border-radius: 4px !important;
  }


  .topbar.mat-toolbar #langToggle .mat-select-trigger {
    width: 100px;
    min-width: 100px;
  }

  .mat-nav-list .mat-list-item:hover,
  .mat-nav-list .mat-list-item.mat-list-item-focus,
  .selected {
    background-color: #f1f1f1 !important;
  }

  .expansion-panel-label,
  .settings-panel-label,
  .events-panel-label {
    margin-bottom: 10px;
  }

  .expansion-panel-label-last,
  .settings-panel-label-last,
  .events-panel-label-last {
    margin-bottom: 10px;
  }

  .settings-panel-heading,
  .events-panel-heading {
    margin-bottom: 5px;
  }

  .mat-checkbox-checked .mat-checkbox-background {
    background-color: $main-standard-background !important;
  }

  .form-field-no-label .mat-form-field-infix {
    padding-top: 8px !important;
    border-top: 0 !important;
  }

  .error-count {
    display: inline-block;
    color: #f15e2a;
    border-radius: 10px;
    padding: 0 8px;
    font-size: 12px;
    line-height: 16px;
  }

  .menu-nostyle {
    background: none !important;
    cursor: default !important;
  }

  .menu-control-wrapper {
    height: 60px;
  }

  .menu-control {
    border: 1px solid #d8d8dc;
    border-radius: 4px;
  }

  .mat-select-placeholder {
    font-size: 14px;
    color: #9e9e9e;
  }

  .select-selected .mat-select-placeholder {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .card-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    color: $main-standard-background;
    cursor: pointer;
  }

  .ebo-full-dialog {
    width: 95vw !important;
    max-width: 95vw !important;
  }

  .ebo-full-dialog .mat-dialog-container {
    overflow: hidden !important;
    padding: 20px 24px;
  }

  //.ebo-full-dialog .ebo-dialog-content {
  //  width: 95vw !important;
  //}

  .ebo-small-dialog, .ebo-medium-dialog {
    width: 800px !important;
    max-width: 800px !important;

    &.custom-dialog-edit-wl {
      width: 100% !important;
      max-width: 48vw !important;
      max-height: 85vh;

      .ebo-dialog-content {
        min-width: 100% !important;
        margin: 0 auto;
        width: 100% !important;
      }
    }
  }

  .ebo-medium-dialog {
    width: 82vw !important;
    max-width: 82vw !important;
  }

  .ebo-small-dialog .mat-dialog-container,
  .ebo-medium-dialog .mat-dialog-container {
    overflow: hidden !important;
    padding: 20px 24px;
  }

  //.ebo-small-dialog .ebo-dialog-content,
  //.ebo-medium-dialog .ebo-dialog-content {
  //  width: 82vw !important;
  //  height: auto !important;
  //}

  .ebo-small-dialog .ebo-dialog-form,
  .ebo-medium-dialog .ebo-dialog-form {
    padding: 28px;
  }

  .ebo-dialog-no-bottom .mat-dialog-container {
    padding-bottom: 0 !important;
  }

  .ebo-dialog-title {
    position: relative;
  }

  .close-button {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .ebo-dialog-content {
    border-top: 1px solid #d8d8dc;
    border-bottom: 1px solid #d8d8dc;
    padding: 0;
    //width: 80vw;
    height: auto;
  }

  .ebo-dialog-content .mat-form-field {
    width: 100%;
  }

  .ebo-dialog-actions {
    padding: 20px 0;
    position: relative;
  }

  .ebo-dialog-actions .ebo-button-normal-right {
    position: absolute;
    right: 0;
  }

  .ebo-button-normal {
    color: $main-standard-background;
    font-weight: bold !important;
    letter-spacing: 0.04em !important;
    text-transform: uppercase !important;
  }

  .ebo-dialog-content-settings {
    width: 50%;
    padding: 48px;
  }

  .ebo-tab-header {
    display: block;
    float: left;
    width: 50%;
    color: #666666;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .ebo-tab-header-left {
    padding: 0 48px 10px 62px;
  }

  .ebo-tab-header-right {
    padding: 0 96px 10px 62px;
  }

  .ebo-tab-content {
    display: block;
    float: left;
    width: 50%;
  }

  .ebo-tab-content-left {
    padding: 48px;
    border-top: 1px solid #d8d8dc;
  }

  .ebo-tab-content-right {
    padding: 48px;
    border-top: 1px solid #d8d8dc;
    border-left: 1px solid #d8d8dc;
  }

  .ebo-content-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 14px 0;
    line-height: 1.125 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    border-left: 1px solid #979797;
    border-width: 1px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    border-width: 1px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    border-right: 1px solid #979797;
    border-width: 1px !important;
  }

  .mat-form-field-suffix .material-icons {
    color: rgba(0, 0, 0, 0.54);
  }

  .top-form-field {
    margin-top: 20px;
    margin-left: 10px;
  }

  .top-form-field:first-child {
    margin-left: 0;
  }

  .top-form-field .mat-form-field-wrapper {
    padding: 0;
    margin: 0
  }

  .top-form-field .mat-form-field-outline-start,
  .top-form-field .mat-form-field-outline-gap,
  .top-form-field .mat-form-field-outline-end {
    background-color: #fff;
  }

  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-empty.mat-form-field-label {
    font-size: 14px;
    line-height: 16px;
    color: #9e9e9e;
  }

  .mat-chip {
    line-height: 18px !important;
    display: inline-flex !important;
  }

  .mat-chip.mat-standard-chip {
    background-color: #f1f1f1 !important;
  }

  .mat-chip .mat-icon {
    float: right !important;
  }

  .ebo-type-toggle {
    margin: 30px 0 30px 0;

    .mat-button-toggle-group {
      height: 65px;
    }
  }

  .mat-button-toggle-label-content {
    width: 100%;
    padding: 8px 12px !important;
    line-height: 24px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
  }

  .ebo-type-toggle-text {
    text-align: center;
    line-height: 1.2;
    font-size: 10px;
    font-weight: $font-weight-medium;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  .ebo-slide-toggle-wrapper {
    margin: .25em 0;
    padding-bottom: 1.34375em;
  }

  .form-field-no-bottom .mat-form-field-wrapper {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-form-field-appearance-outline .mat-input-element,
  .mat-form-field-appearance-outline .mat-select-value-text {
    color: #000000;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .toggle-no-checked-buttons .mat-button-toggle-checked {
    background-color: $secondary-standard-background !important;
  }

  .toggle-checked-buttons .mat-button-toggle-checked {
    background-color: #f8485e !important;
    border: 1px solid #f8485e;
    color: #fff !important;
  }

  .mat-button-toggle-appearance-standard {
    background-color: rgba(224, 224, 224, 0.53) !important;
  }

  .mat-button-toggle-group-appearance-standard {
    background: #FFFFFF;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .ebo-bot-action {
    margin-top: 50px;
    position: relative;
  }

  .ebo-bot-action-create {
    padding-bottom: 26px;
  }

  .ebo-bot-action:first-child {
    margin-top: 0;
  }

  .add-bot-action {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    background-color: #f1f1f1 !important;
    border-radius: 4px;
    color: #666666;
    display: block;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.04em;
    height: 48px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .add-bot-action .ebo-icon {
    position: absolute;
    top: 12px;
    left: 14px;
  }

  .add-bot-action-small {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 300px;
  }

  .ebo-icon {
    color: #b9b9b9 !important;
  }

  .ebo-icon-upload-image {
    font-size: 40px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    top: 10px;
    margin-left: 10px;
  }

  .add-message,
  .add-list-item {
    display: inline-block;
    text-transform: uppercase;
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
    color: #666666;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .add-message .ebo-icon,
  .add-list-item .ebo-icon {
    font-size: 20px;
    float: left;
  }

  .ebo-form-field-container .mat-form-field {
    width: 300px;
  }

  .ebo-form-field {
    width: 300px;
  }

  .ebo-container-full .mat-form-field {
    width: 100%;
  }

  .mat-form-field-full {
    width: 100%;
  }

  .mat-dialog-actions {
    margin-bottom: -12px;
  }

  .chip-space .mat-chip {
    margin-right: 5px;
  }

  .no-border {
    border: 0 !important;
  }

  .no-margin {
    margin: 0 !important;
  }

  .grey-text {
    color: $main-standard-background;
    font-weight: normal;
  }

  .title-card-secondary {
    text-transform: none !important;
  }

  .time-text {
    font-weight: normal;
    color: #d8d8dc;
    font-size: 18px;
  }

  .mat-chip-text-only .mat-form-field-infix {
    line-height: 32px !important;
  }

  .chip-text-only .mat-chip-list-wrapper {
    height: 40px !important;
  }

  .no-wrapper-padding .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: 0 !important;
  }

  .form-field-small {
    width: 80px !important;
  }

  .form-field-small .mat-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 5px !important;
  }

  .play-button .mat-button-wrapper {
    padding: 0;
  }

  .play-button .mat-icon {
    position: relative;
    top: 0;
    font-size: 40px;
    height: 40px;
    width: 40px;
  }

  .status-container {
    margin: 3rem 0 2rem 0;
    text-align: center;
    height: 60px;
  }

  .mat-form-field-type-mat-input:not(.mat-form-field-disabled) .mat-form-field-flex {
    cursor: text;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #f44336 !important;
  }

  .mat-form-field.mat-form-field-invalid ::-webkit-input-placeholder {
    color: #f44336 !important;
  }

  .mat-form-field.mat-form-field-invalid ::-moz-placeholder {
    color: #f44336 !important;
  }

  .mat-form-field.mat-form-field-invalid :-ms-input-placeholder {
    color: #f44336 !important;
  }

  .mat-form-field.mat-form-field-invalid :-moz-placeholder {
    color: #f44336 !important;
  }

  .select-with-menu .mat-select-value {
    position: relative;
    top: -6px;
  }

  .tree-edit-wrapper {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 15px;
  }

  .tree-edit {
    display: none;
    position: absolute;
    top: 2px;
    left: 4px;
    font-size: 15px;
  }

  .p-treenode-content.p-treenode-selectable:hover .tree-edit {
    display: inline-block;
  }

  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    padding: 6px 10px;
  }

  .ui-tree-horizontal .ui-treenode-content {
    padding: .4em .2em;
  }


  .accordion-wrapper {
    margin: .25em 0;
    padding-bottom: 1.34375em;
  }

  .accordion-form-fields {
    border: 1px solid #d8d8dc;
    border-radius: 5px;
    padding: 14px 38px 0 14px;
    margin-bottom: 20px;
  }

  .datatable-scroll, .datatable-row-wrapper {
    width: 100% !important;
  }

  .mat-select-search {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .mat-select-search-input {
    flex-shrink: 0;
  }

  .mat-select-search-input input {
    text-indent: 16px;
    border-bottom: 1px solid #d8d8dc;
    font-size: 14px;
    height: 35px;
  }

  .mat-select-search-options {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ebo-dialog-row .ebo-dialog-column {
    padding-right: 10px;
  }

  .ebo-dialog-row .ebo-dialog-column:last-child {
    padding-right: 0;
  }

  .ebo-dialog-column .mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .ebo-dialog-no-bottom-field .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    margin-top: .25em !important;
    padding-top: 1.34375em !important;
  }

  .ebo-dialog-top-space-field,
  .ebo-dialog-top-space-field-pdf {
    margin-top: .25em !important;
    padding-top: 1.34375em !important;
  }

  .mat-tab-body-content {
    overflow: hidden !important;
  }

  p-editor .ql-toolbar {
    background: #f1f1f1 !important;
    border-color: #979797 !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  p-editor .ql-tooltip {
    margin-left: 110px !important;
  }

  p-editor .ql-container {
    border-color: #979797 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .editor-icon-variable {
    color: #444 !important;
    position: relative !important;
    top: 3px !important;
    cursor: pointer;
  }

  .editor-icon-variable:hover {
    color: #06c !important;
  }

  .condition-form-field .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  .condition-form-field .mat-form-field-outline-start,
  .condition-form-field .mat-form-field-outline-gap,
  .condition-form-field .mat-form-field-outline-end {
    background-color: #fff !important;
  }

  .mat-form-field-flex > .mat-form-field-infix { padding: 0.4em 0 !important;}
  .mat-form-field-label-wrapper { top: -1.5em; }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
  }

  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: #8b84d7 !important;
  }
}
